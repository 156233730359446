.project_table {
	.stages {
		width: 200px;
	}

	table {
		td {
			img {
				border-radius: 50%;
			}
		}
	}
}