.pagination {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.records {
		span {
			font-weight: 700;
		}
	}

	.limit {
		select {
			background: transparent;
			color: #fff;
			border: none;
			font-weight: 600;
			.option_style{
				background-color: #3b3363;
			}
		}
	}

	.pages {
		display: flex;
		align-items: center;
		background: #2c254a;
		border-radius: 8px;
		padding: 5px;

		button {
			font-size: 16px;
			border: 0;
			width: auto;
			padding: 10px 14px;
			margin: 0 5px;
			border-radius: 8px;
			background-color: #3b3363;
			color: #fff;

			&:disabled {
				opacity: 0.5;
				cursor: default;
			}
		}

		input {
			width: 50px;
			color: #fff;
			background: transparent;
			border: none;
			text-align: center;
		}
	}
}