.upload_file {
	display: flex;
	width: 100%;
	align-items: flex-end;

	input {
		&[type=file] {
			visibility: hidden;
			width: 0;
			height: 0;
		}

		&[type=text] {
			flex: 1;
			display: flex;
			height: 50px;
			background-color: transparent;
			color: #fff;
			border: 1px solid #5b5289;
			padding-left: 10px;
			border-radius: 0.35rem 0 0 0.35rem;
			cursor: pointer;
		}
	}

	button {
		height: 50px;
		background: #473F72;
		color: #7e7e7e;
		border: none;
		border-radius: 0 0.35rem 0.35rem 0;
		padding: 10px;
	}
}


.button {

	input {
		display: none !important;
	}

	button {
		height: 42px;
		padding: 10px;
		width: 42px;
		border-radius: 6px;
	}

	img {
		width: 42px;
		height: 42px;
	}
}