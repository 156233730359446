.transaction_table {
  .status {
    width: 200px;
  }

  .type {
    width: 200px;
  }

  table {
    td {
      img {
        border-radius: 50%;
      }

      .table_stages {
        background-color: aliceblue;
      }
    }
  }
}
span.refreshIcon {
  cursor: pointer !important;
  transition: all 5ms ease-in-out;
  i {
    font-size: 24px;
  }
  :hover {
    opacity: 0.5;
  }
}
.refresh_btn {
  margin-bottom: 1rem;
}
