.card_profile {
	height: auto !important;

	.profile_info {
		flex-direction: column;
		align-items: center;

		.profile_photo {
			margin: -85px 0 0 0;
			display: flex;
			justify-content: center;
			width: 140px;
			height: 140px;
			position: relative;

			img {
				position: absolute;
				border-radius: 50%;
				width: 140px;
				height: 140px
			}
		}

		.profile_details {
			display: flex;
			justify-content: space-between;

			&>div {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}