.custom_checkbox {
	height: 100%;
	display: flex !important;
	align-items: flex-end;
	padding-bottom: 10px;

	input {
		cursor: pointer;
		margin-bottom: 6px;
		margin-right: 5px;
	}

	label {
		cursor: pointer;
	}
}