@import "../../abstracts/variable";

input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: var(--primary);
    }

}
.form-check-label{
    margin-left: 5px;
    margin-top: 3px;
}


.form-check-inline {
    .form-check-input {
        margin-right: 0.625rem;
    }
}
.custom-control-label{
	&:before,
	&:after{
		top:2px;
		width: 1.25rem;
		height: 1.25rem;
		border-color: #e7e7e7;
	}
}
.rtl .custom-control-label{
	&:before,
	&:after{
		right: -1.5rem !important;
		left: inherit;
	}
}
.custom-control {
    line-height: normal;
}


// Toggle Switch
.toggle-switch{
	padding-left:50px;
	line-height: 1.25;
	.custom-control-label{
		
		font-weight: 500;
		&:after{
			width: 24px;
			background:#959595;
			height: 24px;
			border-radius: 24px;
			top: -2px;
			left: -50px;
			@include transitionMedium;
		}
		&:before{
			border: 0;
			background: #D8D8D8;
			width: 40px;
			border-radius: 20px !important;
			height: 16px;
			left: -50px;
			@include transitionMedium
		}
	}
	.custom-control-input:checked ~ .custom-control-label{
		&:after{
			left: -43px;
			background:var(--primary);
		}
		&:before{
			background: var(--rgba-primary-1);
		}
	}
	&.text-right{
		padding-left:0;
		padding-right:50px;
		.custom-control-label{
			&:after{
				left: auto;
				right: -30px;
			}
			&:before{
				left: auto;
				right: -50px;	
			}
		}
		.custom-control-input:checked ~ .custom-control-label{
			&:after{
				left: auto;
				right: -43px;
			}
		}
	}
}


// CheckBox
.custom-control-input:focus ~ .custom-control-label::before{
	box-shadow:none !important;
}
.custom-control-label::before{
    background-color: transparent;
    border-color: $light;
	border-width: 2px;
	border-radius: 0.25rem !important;
		
	@at-root [data-theme-version="dark"] & {
		background-color: transparent;
		border-color: $d-border;
	}
}
.check-xs{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 18px;
		height: 18px;
	}
}
.check-lg{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 24px;
		height: 24px;
	}
}
.check-xl{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 28px;
		height: 28px;
	}
}
.checkbox{
	&-info{
		.custom-control-label::before{
			background-color: $info-light;
			border-color: $info-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $info-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $info;
			border-color: $info;
		}
	}
	&-danger {
		.custom-control-label::before{
			background-color: $danger-light;
			border-color: $danger-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $danger-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $danger;
			border-color: $danger;
		}
	}
	&-success{
		.custom-control-label::before{
			background-color: $success-light;
			border-color: $success-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $success-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $success;
			border-color: $success;
		}
	}
	&-warning{
		.custom-control-label::before{
			background-color: $warning-light;
			border-color: $warning-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $warning-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $warning;
			border-color: $warning;
		}
	}
	&-secondary{
		.custom-control-label::before{
			background-color: $secondary-light;
			border-color: $secondary-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $secondary-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}




//Check Switch
.check-switch{
	padding-left: 40px;
	.custom-control-label{
		line-height: 30px;
		font-weight: 500;
		span{
			line-height: 1;
		}
		&:after,&:before{
			height:1.5rem;
			width:1.5rem;
			left: -2rem;
			border-radius: 3rem!important;
			border-color:$black;
		}
	}
	.custom-control-input:checked ~ .custom-control-label::after{
		background-image:url('../images/svg/check.svg');
	}
	.custom-control-input:checked ~ .custom-control-label::before{
		background:$white;
	}
}
















